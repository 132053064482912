<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Hourly Return">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <hourly-trade-returns :showChart="false"></hourly-trade-returns>
                        </div>
                    </div>
                </div>
            </div>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import HourlyTradeReturns from '@/widgets/HourlyTradeReturns';

export default {
    components: {
        TopHeader,
        SideNavbar,
        MainContent,
        HourlyTradeReturns
    }
}
</script>