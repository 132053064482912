<template>
    <div class="hourly-trade-returns-widget">
        <div class="col-12 d-flex align-items-center justify-content-end" v-if="!showTable && showChart">
            <b-form-select id="hourly-trade-returns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-end mb-2">
            <b-button variant="light" class="mr-auto" @click="onExport">
                Export
            </b-button>
            <b-form inline @submit.prevent>
                <label class="mr-sm-2" for="hourly-trade-returns-type-filter">Type:</label>
                <b-form-select id="hourly-trade-returns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
                <label class="mr-sm-2" for="hourly-trade-returns-notation-filter">Notation:</label>
                <b-form-select id="hourly-trade-returns-notation-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="notation" :options="filterOptions.notation" @change="onNotationChange"></b-form-select>
            </b-form>
            <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2">
                <template v-slot:input="picker" style="min-width: 350px;">
                    <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                    <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                </template>
            </date-range-picker>
        </div>
        <highcharts :options="chartOptions" :style="`height: ${height};`" v-if="showChart"></highcharts>
        <div class="col-12 d-flex align-items-center justify-content-end mb-2" v-if="showTable && showChart">
            <b-button variant="light" class="mr-auto" @click="onExport">
                Export
            </b-button>
            <b-form inline @submit.prevent>
                <label class="mr-sm-2" for="hourly-trade-returns-type-filter">Type:</label>
                <b-form-select id="hourly-trade-returns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="type" :options="filterOptions.type" @change="requests"></b-form-select>
                <label class="mr-sm-2" for="hourly-trade-returns-notation-filter">Notation:</label>
                <b-form-select id="hourly-trade-returns-notation-filter" class="mb-2 mr-sm-2 mb-sm-0" v-model="notation" :options="filterOptions.notation" @change="onNotationChange"></b-form-select>
            </b-form>
            <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2" :ranges="dateRanges">
                <template v-slot:input="picker" style="min-width: 350px;">
                    <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                    <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                </template>
            </date-range-picker>
        </div>
        <template v-if="showTable">
            <b-table id="hourly-trade-returns-table" :items="tableOptions.items" :fields="tableOptions.columns" responsive class="text-center"
                show-empty :per-page="50" :current-page="tableOptions.currentPage">
                <template slot="empty">
                    No results found
                </template>
                <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <template v-if="field.key === 'timestamp'">Total</template>
                    <template v-else>{{getSum(field.key)}}</template>
                </td>
                </template>
                <template v-slot:cell()="data">
                    <span v-if="parseFloat(data.value) === 0">-</span>
                    <span v-else-if="parseFloat(data.value) > 0" style="color: green;">
                        {{data.value}}
                    </span>
                    <span v-else-if="parseFloat(data.value) < 0" style="color: red;">
                        {{data.value}}
                    </span>
                    <span v-else>
                        {{data.value}}
                    </span>
                </template>
            </b-table>
            <div class="row mt-3">
                <div class="col-12 d-flex align-items-center justify-content-start">
                    <b-pagination v-model="tableOptions.currentPage" :total-rows="tableOptions.items.length" :per-page="50" aria-controls="hourly-trade-returns-table"></b-pagination>
                </div>
            </div>
        </template>
        <div class="row">
            <div class="col-12 text-right">
                <small>Returns for the hour use the start of the hour as the timestamp.</small>
            </div>
        </div>
    </div>
</template>

<script>
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import { numberFormat } from 'highcharts';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';
import { map, clone } from 'lodash';

export default {
    name: 'hourly-trade-returns',
    data() {
        let date = new Date();

        return {
            dateRange: {
                startDate: new Date('2020-01-01'),
                endDate: endOfMonth(date)
            },
            type: 'Market',
            notation: 'Tick',
            timePeriod: 'Hourly',
            filterOptions: {
                type: [
                    {
                        value: 'Market',
                        text: 'Market'
                    },
                    {
                        value: 'Bucket',
                        text: 'Bucket'
                    },
                    {
                        value: 'Factor',
                        text: 'Factor'
                    }
                ],
                notation: [
                    {
                        value: 'Bps',
                        text: 'Bps'
                    },
                    {
                        value: 'Tick',
                        text: 'Tick'
                    }
                ],
                timePeriod: [
                    {
                        value: 'Monthly',
                        text: 'Monthly'
                    },
                    {
                        value: 'Weekly',
                        text: 'Weekly'
                    },
                    {
                        value: 'Daily',
                        text: 'Daily'
                    },
                    {
                        value: 'Hourly',
                        text: 'Hourly'
                    }
                ]
            },
            chartOptions: {
                chart: {
                    type: 'line',
                    zoomType: 'x'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        text: 'Return'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function() {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    valueDecimals: 2
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            symbol: 'circle'
                        }
                    }
                },
                rangeSelector: {
                    enabled: true,
                    verticalAlign: 'top',
                    x: 0,
                    y: 0
                },
                series: []
            },
            tableOptions: {
                columns: [],
                items: [],
                currentPage: 1
            },
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2020-01-01'), endOfYear(date)]
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        },
        showTable: {
            type: Boolean,
            default: true
        },
        showChart: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: '400px'
        }
    },
    components: {
        DateRangePicker
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('hourlytradereturn', this.onHourlyTradeReturns);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('hourlytradereturn', this.onHourlyTradeReturns);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'HourlyTradeReturn',
                args: {
                    filterType: this.type,
                    notationType: this.notation,
                    timePeriod: this.timePeriod,
                    startDate: format(this.dateRange.startDate, 'yyyy-MM-dd'),
                    endDate: format(this.dateRange.endDate, 'yyyy-MM-dd')
                }
            });
        },
        onHourlyTradeReturns(event) {
            let that = this;

            let data = {};

            let columns = [{
                key: 'timestamp',
                label: 'Timestamp',
                sortable: true
            }];

            for(let key in event.response.totals) {
                columns.push({
                    key: key,
                    label: key,
                    sortable: true,
                    formatter: function(value) {
                        return that.$options.filters.number(value);
                    }
                });
            }

            columns.push({
                key: 'total',
                label: 'Total',
                sortable: true,
                formatter: function(value) {
                    return that.$options.filters.number(value);
                }
            });

            for(let date in event.response.data) {
                let parsedDate = new Date(date);

                let day = format(parsedDate, 'EEEE');
                let hour = parsedDate.getHours() - 1;

                let parentKey = `${day}, Hour: ${hour}`;

                if(!data.hasOwnProperty(parentKey)) {
                    data[parentKey] = {};
                }

                for(let key in event.response.data[date]) {
                    if(!data[parentKey].hasOwnProperty(key)) {
                        data[parentKey][key] = 0;
                    }

                    data[parentKey][key] += event.response.data[date][key];
                }
            }

            let sortWeightings = {
                Monday: 0,
                Tuesday: 1,
                Wednesday: 2,
                Thursday: 3,
                Friday: 4,
                Saturday: 5,
                Sunday: 6
            };

            let timestamps = Object.keys(data).sort(function(a, b) {
                let aDay = a.split(', ')[0];
                let bDay = b.split(', ')[0];

                if(aDay === bDay) {
                    let aSplit = a.split(' ');
                    let bSplit = b.split(' ');

                    let aHour = parseInt(aSplit[aSplit.length - 1]);
                    let bHour = parseInt(bSplit[bSplit.length - 1]);

                    return aHour - bHour;
                }

                return sortWeightings[aDay] - sortWeightings[bDay];
                return sortWeightings[a.split(', ')[0]] - sortWeightings[b.split(', ')[0]];
            });

            let rows = [];
            for(let timestamp of timestamps) {
                let row = {
                    timestamp: timestamp
                };

                let total = 0;
                for(let key in data[timestamp]) {
                    row[key] = data[timestamp][key];
                    total += data[timestamp][key];
                }

                row.total = total;
                rows.push(row);
            }

            this.tableOptions.columns = columns;
            this.tableOptions.items = rows;
        },
        onNotationChange(event) {
            if(this.notation === 'Percentage') {
                this.chartOptions.tooltip.valueSuffix = '%';
                this.chartOptions.tooltip.valuePrefix = '';
                this.chartOptions.tooltip.valueDecimals = 2;
                this.chartOptions.yAxis.labels.formatter = function() {
                    return this.value + '%';
                }
            } else {
                this.chartOptions.tooltip.valueSuffix = '';

                let activeCurrency = this.$store.state.activeCurrency;
                if(activeCurrency === 'GBP') {
                    this.chartOptions.tooltip.valuePrefix = '£';
                } else {
                    this.chartOptions.tooltip.valuePrefix = '$';
                }

                this.chartOptions.tooltip.valueDecimals = 0;

                let that = this;
                this.chartOptions.yAxis.labels.formatter = function() {
                    return that.$options.filters.currency(that.$options.filters.number(this.value, 0));
                }
            }

            this.requests();
        },
        onExport() {
            let headers = {};

            for(let column of this.tableOptions.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.tableOptions.items, clone);
            
            for(let item of items) {
                //escape character to allow commas
                item.timestamp = "\"" + item.timestamp + "\""; 
            }

            exportCsv(headers, items, 'HourlyTradeReturns');
        },
        getSum(field) {
            let sum = 0;
            for(let item of this.tableOptions.items) {
                sum += parseFloat(item[field]);
            }

            return this.$options.filters.number(sum);
        }
    }
}
</script>